import "regenerator-runtime/runtime";

import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
  useParams
} from "react-router-dom";
import { useThrottle } from "use-throttle";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from "@reach/combobox";
import "@reach/combobox/styles.css";
/** @jsx jsx */
import { jsx } from "@emotion/core";

const API_HOST =
  process.env.NODE_ENV === "production"
    ? "https://checkoutapi.ticketbutler.io"
    : "http://localhost:8011";

// There is a secondary WL used for seating events
const API_HOST_SEATING =
  process.env.NODE_ENV === "production"
    ? "https://checkoutapi.ticketbutler.io"
    : "http://localhost:8011";

const logo = require("./assets/logo.svg");

function toLocaleDateString(ts) {
  return new Date(ts).toLocaleDateString("da-DK", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
}

render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

function App() {
  const history = useHistory();

  const [isPopupShowing, setIsPopupShowing] = useState(true);

  return (
    <div
      css={{
        "@media (max-width: 979px)": {
          position: "relative"
        }
      }}
    >
      <div
        css={{
          width: "50px",
          height: "50px",
          borderRadius: "25px",
          boxShadow: "0px 0px 54px 0px rgba(0,0,0,0.75)",
          transition: "700ms",
          backgroundColor: "#fff",
          position: "absolute",
          bottom: "1%",
          right: "1%",
          "@media (max-width: 979px)": {
            position: "fixed"
          },
          ...(isPopupShowing
            ? {
                opacity: 0,
                visibility: "hidden"
              }
            : {
                opacity: 1,
                visibility: "visible"
              })
        }}
        onClick={() => setIsPopupShowing(true)}
      >
        <img
          src={require("./assets/plus_icon.svg")}
          alt="plus"
          css={{
            width: "50px"
          }}
        />
      </div>
      <div
        css={{
          width: "300px",
          height: "190px",
          backgroundColor: "#fff",
          position: "absolute",
          bottom: "1%",
          right: "1%",
          boxShadow: "0px 0px 54px 0px rgba(0,0,0,0.75)",
          transition: "700ms",
          "@media (max-width: 979px)": {
            position: "fixed",
            zIndex: 100
          },
          ...(isPopupShowing
            ? {
                opacity: 1,
                visibility: "visible"
              }
            : {
                opacity: 0,
                visibility: "hidden"
              })
        }}
      >
        <div
          css={{
            textAlign: "right",
            cursor: "pointer",
            zIndex: 1000
          }}
          onClick={() => setIsPopupShowing(false)}
        >
          {" "}
          <svg width="40px" viewBox="0 0 20 20">
            <path
              fill="#AFB4BB"
              d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
            />
          </svg>
        </div>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <p
            css={{
              fontSize: "25px",
              fontFamily: "Bebas Neue, cursive",
              margin: "0px"
            }}
          >
            Mangler din kirke?
          </p>
          <p css={{ fontSize: "12px", marginTop: "0.2em" }}>
            Så kontakt os her
          </p>
          <a
            href="//info.pladsikirken.dk/kontakt/"
            css={{ textDecoration: "none" }}
            target="_blank"
          >
            <button>Kontakt os</button>{" "}
          </a>
        </div>
      </div>
      <div
        css={{
          background: "#fff",
          margin: "100px auto 20px auto",
          width: "90%",
          maxWidth: "1250px",
          minHeight: "50vh",
          boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.3)",
          "@media (max-width: 979px)": {
            width: "100%",
            margin: "0"
          }
        }}
      >
        <header
          css={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Link
            to="/"
            id="logo"
            css={{
              width: "400px",
              margin: "-55px auto 0px",
              "@media (max-width: 979px)": { marginTop: "20px" },
              "@media (max-width: 640px)": { width: "230px" }
            }}
          >
            <img src={logo} alt="Plads i Kirken logo" css={{ width: "100%" }} />
          </Link>
          <div
            css={{
              position: "absolute",
              right: "20px",
              top: "20px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              alignItems: "center",
              "@media (max-width: 740px)": { visibility: "hidden" }
            }}
          >
            <a
              href="//info.pladsikirken.dk/"
              target="_blank"
              rel="noopener noreferrer"
              css={{
                textDecoration: "none",
                marginRight: "15px",
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "14px"
              }}
            >
              Tilmeld din kirke
            </a>
            <a
              href="https://pladsikirken.ticketbutler.io/da/user/login/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                css={{
                  backgroundColor: "#fff",
                  color: "rgba(0, 147, 91, 0.8)",
                  borderColor: "rgba(0, 147, 91, 0.8)",
                  padding: "7.5px 15px"
                }}
              >
                Kirke-login
              </button>
            </a>
          </div>
        </header>
        <div
          css={{
            width: "90%",
            maxWidth: 795,
            margin: "0 auto",
            padding: "70px 0 100px 0",
            "@media (max-width: 979px)": { padding: "40px 0 60px 0" }
          }}
        >
          <Switch>
            <Route exact path="/">
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                <h1
                  css={{
                    textTransform: "uppercase",
                    marginBottom: "20px"
                  }}
                >
                  Book din plads i kirken
                </h1>
                <p
                  css={{
                    margin: "0 0 40px 0",
                    fontSize: "20px"
                  }}
                >
                  Find en koncert nær dig og reservér din plads
                </p>
                <Search
                  onSelect={function(team) {
                    history.push("/kirke/" + team.name, { team });
                  }}
                />
              </div>
            </Route>
            <Route path="/kirke/:teamName">
              <TeamEventsList />
            </Route>
            <Route defaut>
              <h1>404 Not found</h1>
            </Route>
          </Switch>
        </div>
        <footer
          css={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid rgba(0, 147, 91, 0.8)",
            margin: "0 20px",
            padding: "15px 0",
            fontSize: 14,
            color: "rgba(0, 147, 91, 0.8)",
            "@media (max-width: 640px)": {
              flexDirection: "column",
              textAlign: "center"
            }
          }}
        >
          <div css={{ "@media (max-width: 640px)": { marginBottom: "20px" } }}>
            <div>
              <a
                href="https://pladsikirken.ticketbutler.io/da/user/login/"
                css={{ textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Kirke-login
              </a>
            </div>
            <div>
              <a
                href="//info.pladsikirken.dk/privatlivspolitik/"
                css={{ textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privatlivspolitik
              </a>
            </div>
            <div>
              <a
                href="//info.pladsikirken.dk/gdpr"
                css={{ textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                GDPR i billetsystemet
              </a>
            </div>
            <div>
              <a
                href="//info.pladsikirken.dk/kontakt/"
                css={{ textDecoration: "none" }}
                target="_blank"
              >
                Kontakt
              </a>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column"
            }}
          >
            <div>Ponytailclub ApS</div>
            <div>CVR: 31486157</div>
            <div>
              <a href="mailto:mv@koncertikirken.dk">mv@koncertikirken.dk</a>
            </div>
            <div>26 82 23 83</div>
            <div>Langelinie 20</div>
            <div>5230 Odense M</div>
          </div>
        </footer>
      </div>
    </div>
  );
}

const optionStyle = {
  display: "block",
  fontSize: 16,
  padding: "15px 0 15px 15px"
};
function Search({ onSelect }) {
  const [searchTerm, setSearchTerm] = useState("");
  const throttledSearchTerm = useThrottle(searchTerm, 500);

  const [teams, setTeams] = useState();

  useEffect(() => {
    if (throttledSearchTerm.trim() !== "") {
      let isFresh = true;
      fetchTeams(throttledSearchTerm).then(teams => {
        if (isFresh) setTeams(teams);
      });
      return () => (isFresh = false);
    } else {
      // Empty list
      setTeams();
    }
  }, [throttledSearchTerm]);

  async function fetchTeams(value) {
    const [teams1, teams2] = await Promise.all([
      fetch(
        API_HOST +
          "/api/events/search/teams/?whitelabel=pladsikirken&search=" +
          value
      ).then(res => (res.ok ? res.json() : [])),
      fetch(
        API_HOST_SEATING +
          `/api/events/search/teams/?whitelabel=${encodeURIComponent(
            "pladsikirken-billetsystem"
          )}&search=` +
          value
      ).then(res => (res.ok ? res.json() : []))
    ]);
    return [...teams1, ...teams2];
  }

  return (
    <Combobox
      aria-label="Kirke"
      css={{
        width: "100%",
        position: "relative"
      }}
      onSelect={teamName => {
        let team = teams.find(({ name }) => name === teamName);
        if (team) onSelect(team);
      }}
    >
      <div
        css={{
          padding: "16px",
          borderRadius: "10px",
          border: "1px solid rgba(0, 147, 91, 0.4)",
          margin: "0 auto",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.15)",
          display: "flex",
          alignItems: "center"
        }}
      >
        <img
          src={require("./assets/search_icon.svg")}
          alt="Søg"
          css={{ marginRight: "15px" }}
        />
        <ComboboxInput
          type="search"
          placeholder="Søg efter kirke, by eller præst"
          css={{
            border: "none",
            flexGrow: 1
          }}
          onChange={event => {
            setSearchTerm(event.target.value);
          }}
        />
      </div>
      {teams && (
        <ComboboxPopover
          portal={false}
          css={{
            position: "absolute",
            border: "1px solid rgba(0, 147, 91, 0.4)",
            borderTop: "1px dashed #ECEBED",
            borderRadius: "10px",
            width: "100%",
            marginTop: "2px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0 7px 64px 0",
            textAlign: "left"
          }}
        >
          {teams.length > 0 ? (
            <ComboboxList>
              {teams.map(team => {
                return (
                  <ComboboxOption
                    key={team.uuid}
                    value={team.name}
                    css={optionStyle}
                  />
                );
              })}
            </ComboboxList>
          ) : (
            <span css={optionStyle}>Ingen kirke fundet</span>
          )}
        </ComboboxPopover>
      )}
    </Combobox>
  );
}

function TeamEventsList() {
  const { teamName } = useParams();
  const { state = {} } = useLocation();
  const [team, setTeam] = useState(state.team);

  // Get the team if we don't have it from the location state
  useEffect(() => {
    async function getTeams() {
      if (!team) {
        const [teams1, teams2] = await Promise.all([
          fetch(
            API_HOST +
              "/api/events/search/teams/?whitelabel=pladsikirken&search=" +
              teamName
          ).then(res => (res ? res.json() : [])),
          fetch(
            API_HOST_SEATING +
              `/api/events/search/teams/?whitelabel=${encodeURIComponent(
                "pladsikirken-billetsystem"
              )}&search=` +
              teamName
          ).then(res => (res ? res.json() : []))
        ]);

        // We just use the first result in the search
        setTeam(teams1[0] || teams2[0]);
      }
    }
    getTeams();
  }, []);

  const [events, setEvents] = useState();
  useEffect(() => {
    async function getEvents() {
      // When we have a team, get the events
      if (team) {
        const [events1, events2] = await Promise.all([
          fetch(
            API_HOST +
              "/api/events/search/events/?whitelabel=pladsikirken&teams=" +
              team.slug
          ).then(res => (res ? res.json() : [])),
          fetch(
            API_HOST_SEATING +
              `/api/events/search/events/?whitelabel=${encodeURIComponent(
                "pladsikirken-billetsystem"
              )}&teams=` +
              team.slug
          ).then(res => (res ? res.json() : []))
        ]);
        setEvents([...events1, ...events2]);
      }
    }
    getEvents();
  }, [team]);

  return !team ? (
    "Fetching kirke..."
  ) : (
    <div>
      <div css={{ textAlign: "center" }}>
        <h1
          css={{
            textTransform: "uppercase",
            marginBottom: "20px"
          }}
        >
          {team.name}
        </h1>
        <div css={{ marginBottom: 10 }}>
          {team.address.street} {team.address.street_2}
          <br />
          {team.address.postcode} {team.address.city}
        </div>
        <div>
          <span css={{ fontWeight: "bold" }}>Kontakt til kirken</span>
          <br />
          Email: {team.address.email}
          <br />
          Telefon: {team.address.phone}
        </div>
      </div>
      <div css={{ marginTop: 35 }}>
        {!events
          ? "Fetching events..."
          : events.length < 1
          ? "Der er i øjeblikket ingen begivenheder i denne kirke"
          : events.map(function(event) {
              return (
                <div
                  key={event.uuid}
                  css={{
                    borderBottom: "1px solid rgba(0, 147, 91, 0.8)",
                    display: "flex",
                    flexWrap: "no-wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                    "@media (max-width: 645px)": {
                      flexDirection: "column",
                      textAlign: "center"
                    }
                  }}
                >
                  <div css={{ padding: 5 }}>
                    <h4
                      css={{
                        textTransform: "uppercase",
                        marginBottom: 0
                      }}
                    >
                      {event.title}
                    </h4>
                    {event.tags && (
                      <div css={{ color: "grey" }}>
                        {event.tags
                          .map(tag => tag.name)
                          .join(", ")
                          .replace(/, ([^,]*)$/, " og $1")}
                      </div>
                    )}
                    <div css={{ color: "grey", marginTop: "8px" }}>
                      {[
                        toLocaleDateString(event.start_date),
                        event.end_date && toLocaleDateString(event.end_date)
                      ]
                        .filter(Boolean)
                        .join(" - ")}
                      <br />
                      {[
                        event.address.venue,
                        event.address.street + " " + event.address.street_2
                      ]
                        .filter(Boolean)
                        .join(" - ")}
                      <br />
                      {event.address.postcode} {event.address.city}
                    </div>
                  </div>
                  <div
                    css={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 5,
                      whiteSpace: "pre",
                      "@media (max-width: 645px)": { flexDirection: "column" }
                    }}
                  >
                    <strong
                      css={{
                        marginRight: 20,
                        "@media (max-width: 645px)": { margin: "0 0 10px 0" }
                      }}
                    >
                      {event.price_min === event.price_max
                        ? event.price_min == 0
                          ? "Gratis"
                          : event.price_min + "kr"
                        : [event.price_min, event.price_max].join(" - ") + "kr"}
                    </strong>
                    <a
                      href={event.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        css={{
                          borderColor: "rgba(0, 147, 91, 1)",
                          backgroundColor: "rgba(0, 147, 91, 0.8)",
                          color: "#fff"
                        }}
                      >
                        Se begivenhed
                      </button>
                    </a>
                  </div>
                </div>
              );
            })}
      </div>
      <Link
        to="/"
        css={{ textDecoration: "none", marginTop: 60, display: "block" }}
      >
        <button css={{ margin: "0 auto", display: "block" }}>
          Tilbage til kirke-søgning
        </button>
      </Link>
    </div>
  );
}
